:root {
  --color-cancel: rgb(247, 62, 62);
  --color-ok: rgb(13, 211, 112);
}

.OrderSummary {
  background-color: #fff;
  font-size: 1.6rem;
}
.OrderSummary p {
  margin: 1rem 0;
  font-size: 1.4rem;
}

.OrderSummary ul li {
  font-size: 1.6rem;
  /* color: green; */
}
.OrderSummary ul {
  list-style: none;
  margin: 1.2rem 0;
}

.Button {
  display: flex;
  justify-content: space-between;
}

.Cancel,
.Ok {
  padding: 1rem 3rem;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 12px;
  width: 16rem;
}

.Cancel:hover,
.Ok:hover {
  filter: brightness(0.8);
}

.Cancel {
  background-color: var(--color-cancel);
  color: #fff;
}
.Ok {
  background-color: var(--color-ok);
  color: #fff;
}
@media (max-width: 600px) {
  .Button {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    min-width: 200px;
  }
}
