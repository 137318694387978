.Form {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.Form input {
	width: 80%;
	max-width: 600px;
	min-width: 300px;
	border: none;
	background-color: #eee;
	font-size: 2rem;
	margin: 1rem auto;
	border-radius: 12px;
	padding: 1rem 2rem;
}

.Btn {
	display: flex;

	justify-content: center;
	align-items: center;
	width: 100%;
}

.Btn button {
	padding: 1rem;
}

.Error {
	padding: 0;
	margin: 0 auto;
	text-align: center;
	font-size: 1.4rem;
	font-weight: bold;
	color: rgb(216, 14, 14);
}

@media (min-width: 600px) {
	.ContactData {
		width: 500px;
	}
}

@media (max-width: 500px) {
	.Btn {
		min-width: 300px;
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
}
