.Backdrop {
  position: fixed;
  top: 0;
  left: 0;
  /* background-color: black; */
  background-color: rgba(105, 73, 73, 0.6);
  z-index: 100;
  width: 100%;
  height: 100%;
  animation: backdrop 1.1s ease-out forwards;
}
@keyframes backdrop {
  0% {
    opacity: 0;
    /* transform: translateY(-100vh); */
    /* transform: scale(1); */
  }

  100% {
    opacity: 0.6;
    /* transform: translateY(0); */
    /* transform: scale(1); */
  }
}
