.Error {
	padding: 0;
	margin: 0 auto;
	text-align: center;
	font-size: 1.4rem;
	font-weight: bold;
	color: rgb(216, 14, 14);
}

.Btn {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	/* margin: 2rem auto; */
}
.Btn button {
	padding: 1rem;
	text-align: center;
}
