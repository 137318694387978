* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: sans-serif;
  font-size: 55.6%;
}

body {
  font-size: inherit;
}
