.Input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.Label {
  font-weight: bold;
  /* color: rgb(128, 128, 128); */
  display: block;
  margin-bottom: 8px;
}

.InputElement {
  outline: none;
  border: none;
  font: inherit;
  border-radius: 12px;
  background-color: #eee;
  padding: 6px 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgb(165, 165, 165);
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: rgb(165, 165, 165);
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: rgb(165, 165, 165);
}
:-moz-placeholder {
  /* Firefox 18- */
  color: rgb(165, 165, 165);
}

.InputElement:focus {
  outline: none;
  background-color: rgb(218, 228, 250);
}

.Invalid {
  /* background-color: red; */
  font-weight: bold;
  color: #f1696963;
}
.Invalid::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #f1696963;
}
