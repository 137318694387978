.BuildControls {
  width: 100%;
  background-color: #cf8f3e;
  display: flex;
  flex-flow: column;
  align-items: center;
  box-shadow: 0 2px 1px #ccc;
  margin: auto;
  padding: 10px 0;
}

.BuildControls p {
  font-size: 1.2rem;
  color: #fff;
  padding: 0 0.5rem;
}

.Price {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
  font-size: 1.6rem;
  font-weight: bold;
  min-width: 25%;
  border: none;
  color: #000;
  /* background-color: #eee; */
  padding: 1rem 0;
}

.Price button {
  padding: 5px 16px;
  background-color: rgb(29, 146, 255);
  color: #fff;
  border: none;
}

.Price button:not(:disabled) {
  animation: btnAnim 0.3s linear;
}

.Price button:hover {
  background-color: green;
  color: #fff;
  cursor: pointer;
}

.Price button:disabled,
button[disabled] {
  animation: btnAnim2 0.7s forwards;
  /* border: 1px solid #999999; */
  background-color: darkgrey;
  color: #ccc;
  background-color: #fff;
  cursor: not-allowed;
}

.Price p,
button {
  margin: 5px 0;
}

.Price p:first-child {
  color: #000;
  font-size: 1.6rem;
}

.Red {
  background-color: red;
}
@keyframes btnAnim {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes btnAnim2 {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.4;
  }
}
