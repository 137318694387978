.CheckoutSummary {
  text-align: center;
  /* width: 80%; */
  margin: auto;
}
.Alert {
  font-size: 1.5 rem;
  font-weight: bold;
  color: red;
  text-align: center;
  vertical-align: middle;
}

@media (min-width: 600px) {
  .CheckoutSummary {
    width: 500px;
  }
}
@media (min-width: 999px) {
  .CheckoutSummary {
    width: 80%;
  }
}
