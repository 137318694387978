.SideDrawer {
  position: fixed;
  width: 280px;
  max-width: 70%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: #fff;
  padding: 32px 16px;
  box-sizing: border-box;
  /* animation: all 1s ease-in-out; */
  transition: transform 0.3s ease-out;
}

@media (min-width: 500px) {
  .SideDrawer {
    display: none;
  }
}

.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(-100%);
}

.Logo {
  height: 11%;
  margin-bottom: 32px;
}

/* @keyframes close {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes open {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
} */
