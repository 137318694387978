:root {
  --color-danger: rgb(247, 62, 62);
  --color-success: rgb(13, 211, 112);
  --color-toggle: rgb(13, 155, 211);
}
.Button {
  padding: 1rem 3rem;
  margin: 1rem 3rem;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 12px;
  width: 16rem;
}

.Button:hover {
  filter: brightness(0.8);
}

.Button:disabled {
  padding: 1rem 3rem;
  margin: 1rem 3rem;
  outline: none;
  border: none;
  cursor: not-allowed;
  border-radius: 12px;
  width: 16rem;
  background-color: #ccc;
  color: rgb(68, 67, 67);
}

.Success {
  background-color: var(--color-success);
  color: #fff;
}
.Toggle {
  background-color: var(--color-toggle);
  color: #fff;
}
.Danger {
  background-color: var(--color-cancel);
  color: #fff;
}

@media (max-width: 391px) {
  .Button {
    margin-bottom: 1rem;
  }
}
