.Modal {
  position: fixed;
  z-index: 500;
  background-color: white;

  max-width: 70%;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 2px 8px 12px rgba(0, 0, 0, 0.1);
  padding: 16px;
  left: 15%;
  top: 30%;
  box-sizing: border-box;
  /* transition: all 1s ease-out; */
  animation: modal 0.8s ease-out forwards;
}

@keyframes modal {
  0% {
    opacity: 0;
    /* transform: translateY(-100vh); */
    /* transform: scale(1); */
  }
  50% {
    /* transform: scale(1.1); */
  }

  100% {
    opacity: 1;
    /* transform: translateY(0); */
    transform: scale(1);
  }
}

@media (min-width: 601px) {
  .Modal {
    width: 500px;
    left: calc(50% - 250px);
  }
}
@media (max-width: 600px) {
  .Modal {
    min-width: 250px;
    /* left: calc(50% - 250px); */
  }
}
