.ContactData {
  margin: 20px auto;
  width: 90%;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
}

.Error {
  background-color: #33addd;
  border: none;
  border-radius: 15px;
  color: #fff;
  width: 60%;
  margin: 1rem auto;
  box-sizing: border-box;
  text-align: center;

  font-weight: bold;
}

/* .Form {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
} */

/* .Form input {
  border: none;
  background-color: #eee;
  font-size: 2rem;
  margin: 1rem;
  border-radius: 12px;
  padding: 1rem 2rem;
  width: 100%;
} */

@media (min-width: 600px) {
  .ContactData {
    width: 500px;
  }
}
